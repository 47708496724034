import React from 'react';

export const Button = ({ children, ...props }) => (
  <button
    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
    {...props}
  >
    {children}
  </button>
);

export const Input = (props) => (
  <input
    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    {...props}
  />
);

export const Label = ({ children, ...props }) => (
  <label className="block text-sm font-medium text-gray-700" {...props}>
    {children}
  </label>
);

export const Card = ({ children, className, ...props }) => (
  <div className={`bg-white shadow-md rounded-lg ${className}`} {...props}>
    {children}
  </div>
);

export const CardHeader = ({ children, ...props }) => (
  <div className="px-4 py-5 border-b border-gray-200 sm:px-6" {...props}>
    {children}
  </div>
);

export const CardContent = ({ children, ...props }) => (
  <div className="px-4 py-5 sm:p-6" {...props}>
    {children}
  </div>
);

export const CardTitle = ({ children, ...props }) => (
  <h3 className="text-lg leading-6 font-medium text-gray-900" {...props}>
    {children}
  </h3>
);

export const Table = ({ children, ...props }) => (
  <table className="min-w-full divide-y divide-gray-200" {...props}>
    {children}
  </table>
);

export const TableHead = ({ children, ...props }) => (
  <th
    className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    {...props}
  >
    {children}
  </th>
);

export const TableRow = ({ children, ...props }) => (
  <tr className="bg-white" {...props}>
    {children}
  </tr>
);

export const TableCell = ({ children, ...props }) => (
  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" {...props}>
    {children}
  </td>
);

export const TableHeader = ({ children, ...props }) => (
  <thead className="bg-gray-50" {...props}>
    {children}
  </thead>
);

export const TableBody = ({ children, ...props }) => (
  <tbody className="bg-white divide-y divide-gray-200" {...props}>
    {children}
  </tbody>
);