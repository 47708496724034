import React, { useState, useEffect, useCallback } from 'react';
import { Button, Input, Label, Card, CardContent, CardHeader, CardTitle, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './components/ui';
import { AlertCircle, X, Plus, Trash, ArrowUp, ArrowDown, Edit } from 'lucide-react';
//import jugendlicheNamen from './jugendliche.txt';

const STORAGE_KEY = 'dienstVerteilerData';

const DienstVerteiler = () => {
  const [dienste, setDienste] = useState([]);
  const [jugendliche, setJugendliche] = useState([]);
  const [tage, setTage] = useState(10);
  const [jugendlicheProDienst, setJugendlicheProDienst] = useState(1);
  const [verteilung, setVerteilung] = useState([]);
  const [currentTag, setCurrentTag] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentDienst, setCurrentDienst] = useState(0);
  const [currentJugendlicherIndex, setCurrentJugendlicherIndex] = useState(0);
  const [shufflingNames, setShufflingNames] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [isShuffling, setIsShuffling] = useState(false);
  const [verfügbareJugendliche, setVerfügbareJugendliche] = useState([]);
  const [tagesDienste, setTagesDienste] = useState([]);
  const [isGroupSelection, setIsGroupSelection] = useState(false);
  const [dienstAbgeschlossen, setDienstAbgeschlossen] = useState(false);
  const [startDatum, setStartDatum] = useState(new Date().toISOString().split('T')[0]);
  const [backgroundHue, setBackgroundHue] = useState(0);
  const [neuerDienst, setNeuerDienst] = useState('');
  const [editingDienst, setEditingDienst] = useState(null);
  
  useEffect(() => {
    fetch('./jugendliche.txt')
    .then(response => response.text())
    .then(text => {
      const namen = text.split('\n').filter(name => name.trim() !== '');
      setJugendliche(namen);
    });
    
    // Lade gespeicherte Daten
    const savedData = localStorage.getItem(STORAGE_KEY);
    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData);
        setDienste(parsedData.dienste || []);
        setTage(parsedData.tage || 10);
        setJugendlicheProDienst(parsedData.jugendlicheProDienst || 1);
        setVerteilung(parsedData.verteilung || []);
        setCurrentTag(parsedData.currentTag || 0);
        setStartDatum(parsedData.startDatum || new Date().toISOString().split('T')[0]);
      } catch (error) {
        console.error('Fehler beim Laden der gespeicherten Daten:', error);
      }
    }
  }, []);
  
  useEffect(() => {
    // Speichere Daten bei jeder Änderung
    const dataToSave = {
      dienste,
      tage,
      jugendlicheProDienst,
      verteilung,
      currentTag,
      startDatum
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(dataToSave));
  }, [dienste, tage, jugendlicheProDienst, verteilung, currentTag, startDatum]);

  useEffect(() => {
    if (isFullscreen) {
      const interval = setInterval(() => {
        setBackgroundHue(prevHue => (prevHue + 1) % 360);
      }, 50);
      return () => clearInterval(interval);
    }
  }, [isFullscreen]);

  const shuffleArray = (array) => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const animateNameSelection = useCallback((availableJugendliche, count) => {
    return new Promise((resolve) => {
      setIsShuffling(true);
      let duration = 3000;
      let intervalTime = 50;
      let iterations = duration / intervalTime;
      let currentIteration = 0;

      const interval = setInterval(() => {
        setShufflingNames(shuffleArray(availableJugendliche).slice(0, count));
        currentIteration++;

        if (currentIteration >= iterations) {
          clearInterval(interval);
          const selected = shuffleArray(availableJugendliche).slice(0, count);
          setSelectedNames(prev => [...prev, ...selected]);
          setIsShuffling(false);
          resolve(selected);
        }

        intervalTime += 1;
      }, intervalTime);
    });
  }, []);

  const startTagVerteilung = useCallback(() => {
    setIsFullscreen(true);
    setCurrentDienst(0);
    setCurrentJugendlicherIndex(0);
    setVerfügbareJugendliche([...jugendliche]);
    setTagesDienste([]);
    setSelectedNames([]);
    setDienstAbgeschlossen(false);
  }, [jugendliche]);

  const beendeTagVerteilung = useCallback(() => {
    setVerteilung(prev => {
      const newVerteilung = [...prev];
      newVerteilung[currentTag] = tagesDienste;
      return newVerteilung;
    });
    setCurrentTag(prev => prev + 1);
    setIsFullscreen(false);
  }, [currentTag, tagesDienste]);

  const verteileDienst = useCallback(async () => {
    if (dienstAbgeschlossen) {
      if (currentDienst < dienste.length - 1) {
        setCurrentDienst(prev => prev + 1);
        setCurrentJugendlicherIndex(0);
        setSelectedNames([]);
        setDienstAbgeschlossen(false);
      } else {
        beendeTagVerteilung();
      }
      return;
    }

    if (currentDienst < dienste.length) {
      const remainingSlots = jugendlicheProDienst - currentJugendlicherIndex;
      const selectionCount = isGroupSelection ? remainingSlots : 1;

      const selectedJugendliche = await animateNameSelection(verfügbareJugendliche, selectionCount);
      setVerfügbareJugendliche(prev => prev.filter(j => !selectedJugendliche.includes(j)));
      setCurrentJugendlicherIndex(prev => prev + selectionCount);

      if (verfügbareJugendliche.length < jugendlicheProDienst) {
        setVerfügbareJugendliche([...jugendliche]);
      }

      if (currentJugendlicherIndex + selectionCount >= jugendlicheProDienst) {
        setTagesDienste(prev => [
          ...prev,
          { dienst: dienste[currentDienst], jugendliche: selectedNames.concat(selectedJugendliche) }
        ]);
        setDienstAbgeschlossen(true);
      }
    }
  }, [dienste, currentDienst, currentJugendlicherIndex, jugendlicheProDienst, verfügbareJugendliche, animateNameSelection, selectedNames, jugendliche, isGroupSelection, dienstAbgeschlossen, beendeTagVerteilung]);

  const schnellVerteilen = useCallback((tagIndex = currentTag) => {
    let verfügbareJugendliche = [...jugendliche];
    const tagesDienste = dienste.map(dienst => {
      const dienstJugendliche = shuffleArray(verfügbareJugendliche).slice(0, jugendlicheProDienst);
      verfügbareJugendliche = verfügbareJugendliche.filter(j => !dienstJugendliche.includes(j));
      if (verfügbareJugendliche.length < jugendlicheProDienst) {
        verfügbareJugendliche = [...jugendliche];
      }
      return { dienst, jugendliche: dienstJugendliche };
    });

    setVerteilung(prev => {
      const newVerteilung = [...prev];
      newVerteilung[tagIndex] = tagesDienste;
      return newVerteilung;
    });
    if (tagIndex === currentTag) {
      setCurrentTag(prev => prev + 1);
    }
  }, [dienste, jugendliche, jugendlicheProDienst, currentTag]);

  const abbrechenVerteilung = () => {
    setIsFullscreen(false);
    setCurrentDienst(0);
    setCurrentJugendlicherIndex(0);
    setSelectedNames([]);
    setDienstAbgeschlossen(false);
  };

  const reset = () => {
    setVerteilung(Array(tage).fill(null));
    setCurrentTag(0);
  };

  const resetTag = (tagIndex) => {
    setVerteilung(prevVerteilung => {
      prevVerteilung[tagIndex] = null; // Setze den ausgewählten Tag zurück
      return [...prevVerteilung]; // Gebe das modifizierte Array zurück
    });
  };
  
  const getDateString = (dayOffset) => {
    const date = new Date(startDatum);
    date.setDate(date.getDate() + dayOffset);
    return date.toLocaleDateString('de-DE', { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const addDienst = () => {
    if (neuerDienst.trim() !== '') {
      setDienste(prev => [...prev, neuerDienst.trim()]);
      setNeuerDienst('');
    }
  };

  const removeDienst = (index) => {
    setDienste(prev => prev.filter((_, i) => i !== index));
  };

  const moveDienst = (index, direction) => {
    setDienste(prev => {
      const newDienste = [...prev];
      const temp = newDienste[index];
      newDienste[index] = newDienste[index + direction];
      newDienste[index + direction] = temp;
      return newDienste;
    });
  };

  const startEditingDienst = (index) => {
    setEditingDienst({ index, name: dienste[index] });
  };

  const saveEditedDienst = () => {
    if (editingDienst) {
      setDienste(prev => {
        const newDienste = [...prev];
        newDienste[editingDienst.index] = editingDienst.name;
        return newDienste;
      });
      setEditingDienst(null);
    }
  };

  return (
    <div className="p-4">
      {isFullscreen ? (
        <div className="fixed inset-0 z-50 flex flex-col items-center justify-center text-white" style={{ background: `hsl(${backgroundHue}, 70%, 60%)` }}>
          <button onClick={abbrechenVerteilung} className="absolute top-4 right-4 text-white">
            <X size={24} />
          </button>
          <h2 className="text-4xl mb-8 font-bold">{getDateString(currentTag)} (Tag {currentTag + 1})</h2>
          <h3 className="text-3xl mb-8">{dienste[currentDienst]}</h3>
          <div className="text-6xl font-bold mb-8 h-24 flex items-center justify-center">
            {isShuffling ? (
              <div className="animate-pulse">{shufflingNames.join(', ')}</div>
            ) : (
              <div className="animate-bounce">{selectedNames.join(', ')}</div>
            )}
          </div>
          <div className="mt-8 text-xl">
            {isShuffling ? "Auswahl läuft..." : dienstAbgeschlossen ? "Dienst abgeschlossen!" : "Auswahl abgeschlossen!"}
          </div>
          <div className="mt-8 flex space-x-4">
            {dienstAbgeschlossen ? (
              <Button onClick={verteileDienst} className="px-4 py-2 text-blue-600 bg-white rounded hover:bg-white-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                {currentDienst < dienste.length - 1 ? "Nächsten Dienst verteilen" : "Verteilung abschließen"}
              </Button>
            ) : (
              <>
                <Button onClick={() => { setIsGroupSelection(false); verteileDienst(); }} className="px-4 py-2 text-blue-600 bg-white rounded hover:bg-white-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                  Einzeln auslosen
                </Button>
                <Button onClick={() => { setIsGroupSelection(true); verteileDienst(); }} className="px-4 py-2 text-blue-600 bg-white rounded hover:bg-white-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                  Gruppe auslosen
                </Button>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <Card className="mb-4">
            <CardHeader>
              <CardTitle>Jugendfreizeit Österreich 2024 - Dienste</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-3 gap-4 mb-4">
                <div>
                  <Label htmlFor="tage">Anzahl der Tage</Label>
                  <Input
                    id="tage"
                    type="number"
                    value={tage}
                    onChange={(e) => setTage(parseInt(e.target.value))}
                    min="1"
                  />
                </div>
                <div>
                  <Label htmlFor="jugendlicheProDienst">Jugendliche pro Dienst</Label>
                  <Input
                    id="jugendlicheProDienst"
                    type="number"
                    value={jugendlicheProDienst}
                    onChange={(e) => setJugendlicheProDienst(parseInt(e.target.value))}
                    min="1"
                  />
                </div>
                <div>
                  <Label htmlFor="startDatum">Startdatum</Label>
                  <Input
                    id="startDatum"
                    type="date"
                    value={startDatum}
                    onChange={(e) => setStartDatum(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex space-x-2 mb-4">
                <Button onClick={startTagVerteilung} disabled={currentTag >= tage}>
                  Nächsten Tag verteilen
                </Button>
        <Button onClick={() => schnellVerteilen()} disabled={currentTag >= tage}>
        Schnell verteilen
        </Button>
        <Button onClick={reset} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
        Zurücksetzen
        </Button>
        </div>
        <div className="mb-4">
        <Label htmlFor="neuerDienst">Neuen Dienst hinzufügen</Label>
        <div className="flex space-x-2">
        <Input
        id="neuerDienst"
        type="text"
        value={neuerDienst}
        onChange={(e) => setNeuerDienst(e.target.value)}
        />
        <Button onClick={addDienst}>
        <Plus size={24} />
        </Button>
        </div>
        </div>
        <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Aktuelle Dienste</h3>
        <ul>
        {dienste.map((dienst, index) => (
          <li key={index} className="flex justify-between items-center mb-2">
          {editingDienst && editingDienst.index === index ? (
            <Input
            value={editingDienst.name}
            onChange={(e) => setEditingDienst({ ...editingDienst, name: e.target.value })}
            onBlur={saveEditedDienst}
            onKeyPress={(e) => e.key === 'Enter' && saveEditedDienst()}
            />
          ) : (
            <span>{dienst}</span>
          )}
          <div className="flex space-x-2">
          <Button onClick={() => startEditingDienst(index)} className="px-2 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600">
          <Edit size={16} />
          </Button>
          <Button onClick={() => moveDienst(index, -1)} disabled={index === 0} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">
          <ArrowUp size={16} />
          </Button>
          <Button onClick={() => moveDienst(index, 1)} disabled={index === dienste.length - 1} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">
          <ArrowDown size={16} />
          </Button>
          <Button onClick={() => removeDienst(index)} className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600">
          <Trash size={16} />
          </Button>
          </div>
          </li>
        ))}
        </ul>
        </div>
        </CardContent>
        </Card>
        
        <Table>
        <TableHeader>
        <TableRow>
        <TableHead>Tag</TableHead>
        {dienste.map((dienst, index) => (
          <TableHead key={index}>{dienst}</TableHead>
        ))}
        <TableHead>Aktionen</TableHead>
        </TableRow>
        </TableHeader>
        <TableBody>
        {verteilung.map((tag, tagIndex) => (
          <TableRow key={tagIndex}>
          <TableCell>{getDateString(tagIndex)} (Tag {tagIndex + 1})</TableCell>
          {tag ? (
            tag.map((dienst, dienstIndex) => (
              <TableCell key={dienstIndex}>{dienst.jugendliche.join(", ")}</TableCell>
            ))
          ) : (
            <TableCell colSpan={dienste.length} className="text-center">
            <AlertCircle className="inline mr-2" />
            Noch nicht verteilt
            </TableCell>
          )}
          <TableCell className="space-x-1">
          <Button onClick={() => schnellVerteilen(tagIndex)} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
          Schnell verteilen
          </Button>
          <Button onClick={() => { setCurrentTag(tagIndex); startTagVerteilung(); }} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
          Neu auslosen
          </Button>
          <Button onClick={() => resetTag(tagIndex)} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50">
          Tag zurücksetzen
          </Button>
          </TableCell>
          </TableRow>
        ))}
        </TableBody>
        </Table>
        </>
      )}
    </div>
  );
};

export default DienstVerteiler;